@mixin sf-bold {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@mixin sf-semibold {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@mixin sf-regular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@mixin sf-medium {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@mixin cuttext {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin transition{
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

.switcher {
  > input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  > label {
    cursor: pointer;
    text-indent: -9999px;
    width: 33px;
    height: 20px;
    background: rgba(120, 120, 128, 0.16);;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  > label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
    box-shadow: 0px 1.93548px 0.645161px rgba(0, 0, 0, 0.06), 0px 1.93548px 5.16129px rgba(0, 0, 0, 0.15);
  }

  > input:checked + label {
    background: #81E727;
  }

  > input:disabled + label {
    background: #81E727;
    opacity: 0.5;
  }

  > input:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  > label:active:after {
    width: 25px;
  }
}

.checkbox {
  display: flex;
  align-items: center;

  > input[type="checkbox"] {
    @include transition;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 16px;
    width: 16px;
    border: 1px solid #E4EAF0;
    border-radius: 3.6px;
    margin: 0px;
  }

  > input[type="checkbox"]:checked {
    background: #D3FFB9;
    border: 1px solid #81E727;
  }

  > input[type="checkbox"]:hover {
    filter: brightness(90%);
  }

  > input[type="checkbox"]:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  > input[type="checkbox"]:after {
    @include transition;
    content: url(../imgs/check-mark-icon.svg);
    position: relative;
    left: 15%;
    opacity: 0;
  }

  > input[type="checkbox"]:checked:after {
    opacity: 1;
  }

  > input[type="checkbox"]:disabled:after {
    border-color: #7b7b7b;
  }

  > label {
    @include noselect;
    @include sf-medium;
    font-size: 10px;
    color: #212529;
    padding-left: 8px;
    text-transform: uppercase;
  }
}

.checkbox-circle {
  display: flex;
  align-items: center;

  > input[type="checkbox"] {
    @include transition;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 17px;
    width: 17px;
    border: 1px solid #E4EAF0;
    border-radius: 50%;
    margin: 0px;
  }

  > input[type="checkbox"]:checked {
    background: white;
    border: 1px solid black;
  }

  > input[type="checkbox"]:hover {
    filter: brightness(90%);
  }

  > input[type="checkbox"]:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  > input[type="checkbox"]:after {
    @include transition;
    content: '\25CF';
    position: relative;
    font-size: 19px;
    top: -4px;
    left: 2px;
    opacity: 0;
  }

  > input[type="checkbox"]:checked:after {
    opacity: 1;
  }

  > input[type="checkbox"]:disabled:after {
    border-color: #7F8489;
  }

  > label {
    @include noselect;
    @include sf-medium;
    font-size: 10px;
    color: #7F8489;
    padding-left: 6px;
    text-transform: uppercase;
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

a{
  text-decoration: none;
}

body{
  margin: 0;
  background-color: #E5E5E5;
}

.app-container{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
}

@mixin reels-btn() {
  @include noselect;
  @include transition;
  @include sf-semibold;
  background: #81E727;
  border-radius: 25px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  &:hover {
    cursor: pointer;
    background: #61a523;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
}

@mixin reels-content-btn() {
  @include noselect;
  @include transition;
  @include sf-semibold;
  background: #000000;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.reels-delete-btn{
  @include reels-btn;
  @include sf-medium;
  font-size: 14px;
  line-height: 17px;
  height: 31px;
  color: rgba(253, 253, 255, 0.91);
  background-color: #EB5757;
  text-align: center;
}

.reels-delete-btn:hover{
  background-color: #e15656;
  cursor: pointer;
}

.reels-second-delete-btn{
  @include reels-btn;
  @include transition;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 0);
  color: #FF3232;
  opacity: 0.6;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    background-color: rgba(255, 255, 255, 0);
  }
}

.reels-second-btn{
  @include reels-btn;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 0);
  color: #909294;
  &:hover {
    cursor: pointer;
    color: #5f5f5f;
    background-color: rgba(255, 255, 255, 0);
  }
}

.reels-input{
  @include transition;
  place-content: center;
  @include sf-regular;
  font-size: 12px;
  background: #FFFFFF;
  padding-left: 8px;
  border-radius: 4px;
  border: 1px solid #E4EAF0;
  letter-spacing: -0.15px;
  line-height: 26px;
}

.reels-input:disabled{
  background: #acacac78;
}

.reels-input::placeholder {
  @include sf-regular;
  color: #666666;
  font-size: 12px;
  letter-spacing: -0.15px;
}

.reels-input:focus{
  border: 1px solid #81E727;
  outline:none
}

.reels-input-search{
  @extend .reels-input;
  border: 1px solid #CED4DA;
  border-radius: 26px;
  padding-left: 30px;
  background: url(../imgs/search-icon.svg) no-repeat scroll 14px 10px;
}

.reels-input-search::placeholder {
  color: #999999;
  opacity: 1;
}

.reels-input-file{
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.reels-input-file-container {
  @include transition;
  display: flex;
  height: 30px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  position: relative;
  align-items: center;
  min-width: 0;
}

.reels-input-file-container:hover {
  cursor: pointer;
  opacity: 0.8;
}

.reels-input-file-container-mob {
  @extend .reels-input-file-container;
  height: 40px;
}

.reels-input-file-text {
  @include cuttext;
  @include sf-medium;
  font-size: 12px;
  letter-spacing: -0.15px;
  color: black;
  padding-left: 7px;
}

.reels-input-file-placeholder {
  @include cuttext;
  @include sf-regular;
  color: #6C7177;
  font-size: 12px;
  letter-spacing: -0.15px;
  padding-left: 7px;
}

.reels-input-file-btn {
  background: #F2F2F2;
  border-radius: 3px;
  @include sf-medium;
  font-size: 9px;
  letter-spacing: -0.15px;
  min-width: 61px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.reels-input-file-btn-mob{
  @extend .reels-input-file-btn;
  height: 29px;
  font-size: 10px;
}

.reels-badge-container{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 3px;
}

.reels-badge{
  @include noselect;
  @include transition;
  display: flex;
  max-width: 120px;
  height: 30px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  &-active {
    @extend .reels-badge;
    background: #D3FFB9;
    border: 1px solid #81E727;
  }
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    border: 1px solid #81E727;
    background: #D3FFB9;
  }
}

.reels-badge-text{
  @include cuttext;
  @include transition;
  margin: auto;
  @include sf-regular;
  font-size: 12px;
  color: #7F8489;
  line-height: 16px;
  letter-spacing: -0.15px;
  padding-left: 10px;
  padding-right: 10px;
  &-active {
    @extend .reels-badge-text;
    color: #212529;
  }
}

@mixin reels-container{
  background: #FFFFFF;
  border-radius: 8px;
}

.reels-head-text{
  @include noselect;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.15px;
  @include sf-regular;
}

.reels-second-text {
  @include sf-regular;
  font-size: 12px;
  letter-spacing: -0.15px;
  color: #7F8489;
}

.reels-switcher-text {
  @include noselect;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  @include sf-semibold;
  color: #212529;
}

.reels-attention-text{
  @include noselect;
  letter-spacing: -0.15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  @include sf-bold;
}

.reels-attention-description-text{
  @include noselect;
  letter-spacing: -0.15px;
  color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  line-height: 19px;
  @include sf-regular;
}

.reels-attention-description-text-mob{
  @extend .reels-attention-description-text;
  text-align: center;
}

.drag-dots{
  width: 12px;
  color: #999999;
  -webkit-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}
.drag-dots:after {
  content: '\283F';
  font-size: 25px;
}

.action-dots{
  width: 10px;
  -webkit-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}
.action-dots:after {
  content: '\0085';
  font-size: 30px;
}

.spinner-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.head-panel-container {
  width: 100%;
}

.head-panel-container-mob{
  @extend .head-panel-container;
  display: flex;
  flex-direction: column;
}

.head-panel {
  min-height: 50px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  background-color: black;
  justify-content: center;
  position: relative;
}

.head-panel-mob {
  @extend .head-panel;
  padding-left: 16px;
  padding-right: 16px;
}

.head-panel-mob-items-container {
  top: 50px;
  z-index: 2000;
  position: absolute;
  color: white;
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.head-item-link {
  @include noselect;
  @include transition;
  display: flex;
  align-items: center;
  justify-content: center;
  @include sf-semibold;
  font-size: 14px;
  line-height: 17px;
  margin-left: 20px;
  margin-right: 20px;
  color: #ABABAB;
  fill: #ABABAB;
  &-active {
    @extend .head-item-link;
    color: #81E727;
    fill: #81E727;
  }
}

.head-item-link:hover {
  color: #589621;
  fill: #589621;
}

.head-item-text {
  padding-left: 9px;
}

.head-item-logout {
  @include noselect;
  @include transition;
  display: flex;
  align-items: center;
  justify-content: center;
  @include sf-semibold;
  font-size: 14px;
  line-height: 17px;
  color: #F13838;
}

.head-item-logout:hover {
  cursor: pointer;
  color: rgba(241, 56, 56, 0.84);
}

.head-item-logo {
  @include noselect;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 4px solid white;
}

.head-item-logo-text {
  @include sf-semibold;
  padding-left: 8px;
  font-size: 14px;
  line-height: 20px;
  color: white;
}

.head-item-logo-container {
  @include noselect;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.modal {
  top: 0!important;
  left: 0!important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  transition: 1.1s ease-out;
  transform: scale(1);
  visibility: visible;
  position: fixed;
  z-index: 1000;
}

.modal-content {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1001;
  max-height: 100vh;
  overflow: auto;
}

.modal-content-mob{
  @extend .modal-content;
  width: 100%;
  height: 100%;
}

.attention-modal-content{
  @extend .modal-content;
}

.attention-modal-content-mob{
  @extend .attention-modal-content;
  width: 80%;
  height: 50%;
}

.body-container {
  display: flex;
  margin: 30px 0px 30px 0px;
  justify-content: center;
  min-height: 0px;
  width: 100%;
  height: 100%;
}

.body-container-mob {
  @extend .body-container;
  margin: 0px;
}

.attention-container{
  background-color: white;
  border-radius: 8.01966px;
  padding-left: 25px;
  padding-right: 25px;
  width: 484px;
}

.attention-container-mob{
  @extend .attention-container;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
}

.attention-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 49px;
}

.attention-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 44px;
  padding-bottom: 20px;
}

.save-btn {
  @include reels-btn;
  @include sf-semibold;
  font-size: 14px;
  line-height: 17px;
  height: 31px;
  text-align: center;
  letter-spacing: -0.2px;
}

.save-btn-footer {
  @include reels-btn;
  @include sf-semibold;
  font-size: 14px;
  line-height: 17px;
  height: 48px;
  width: 100%;
  border-radius: 0;
  text-align: center;
  letter-spacing: -0.2px;
}

.reels-second-btn-footer{
  @extend .reels-second-btn;
  @include sf-semibold;
  font-size: 14px;
  line-height: 17px;
  height: 48px;
  width: 100%;
  border-radius: 0;
  text-align: center;
  letter-spacing: -0.2px;
}

.reels-img-edit-dnd-container{
  @include transition;

  border: 1px solid #E4EAF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4.4px;
  position: relative;
}

.reels-img-edit-dnd-container-active{
  @extend .reels-img-edit-dnd-container;
  border: 1px solid #81E727;
}

.reels-img-edit-dnd-text {
  @include noselect;
  @include sf-medium;
  font-size: 10px;
  line-height: 21px;
  letter-spacing: -0.165517px;
  color: #6C7177;
  padding-top: 13px;
}

.reels-img-edit-container{
  position: relative;
  border-radius: 4.40678px;
  display: flex;
  border: 1px solid rgba(242, 249, 255, 0);
}

.reels-img-edit{
  margin: auto;
  object-fit: cover;
  border: 1px solid #E4EAF0;
  border-radius: 4.40678px;
  &:focus {
    outline: none;
  }
}

.reels-close-img{
  @include transition;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.reels-close-img:hover{
  opacity: 0.8;
  cursor: pointer;
}

//dropdown

.arrow-closed, .arrow-open {
  border: solid #999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 10px;
}

.arrow-closed {
  top: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-open {
  top: 14px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.Dropdown-root {
  @include noselect;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 30px;
  color: white;
  @include sf-semibold;
  padding-left: 8px;
  font-size: 14px;
  line-height: 20px;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: black;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: black;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 85%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 4px 10px;
  color: white;
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: block;
  padding: 4px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  cursor: pointer;
}

.Dropdown-option.is-selected {
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 4px 10px;
}

.reels-dropdown-wrapper {
  display: flex;
  position: relative;
}

.reels-dropdown {
  visibility: visible;
  position: absolute;
  z-index: 999;
  top: 100%;
}

// v2

.library-container{
  display: flex;
  flex-direction: column;
  width: 900px;
}

.section-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow: visible;
}

.library-header{
  display: flex;
  justify-content: space-between;
}

.library-body{
  display: flex;
  justify-content: space-between;
  min-height: 0;
  margin-top: 30px;
}

.content-types-container {
  display: flex;
  align-items: center;
}

.content-types-container-mob {
  @extend .content-types-container;
  margin-left: 10px;
  margin-right: 10px;
}

.content-type-selected{
  @include reels-content-btn;
  border-radius: 3px;
  height: 28px;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
}

.content-type-unselected{
  @extend .content-type-selected;
  background-color: white;
  color: #7F8489;
}

.reels-add-header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.reels-add-header-title {
  @include noselect;
  @include sf-regular;
  font-size: 17px;
  letter-spacing: -0.15px;
  color: #212529;
}

.reels-add-header-add-container{
  @include transition;
  display: flex;
  color: #000000;
  align-items: center;
}

.reels-add-header-add-container:hover{
  opacity: 0.6;
  cursor: pointer;
}

.reels-add-header-text-add {
  @include noselect;
  @include sf-medium;
  font-size: 14px;
  letter-spacing: -0.2px;
  margin-left: 5px;
}

.reels-items-container{
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: visible;
  max-height: 370px;
}
.reels-items-container-none-height{
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: visible;
}
// Component settings

.component-settings-container{
  width: 136px;
  background: #FFFFFF;
  padding-top: 2px;
  padding-bottom: 2px;
  box-shadow: 0px 0px 10px rgba(147, 148, 157, 0.3);
}

.component-settings-container-mob{
  @extend .component-settings-container;
  width: 100px;
}

.component-setting {
  @include noselect;
  @include transition;
  height: 29px;
  width: 100%;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: #F5F5F5;
  }
}

.move-to-container{
  max-height: 253px;
}

.component-setting-text{
  @include cuttext;
  margin-left: 13px;
  margin-right: 13px;
  font-size: 12px;
  @include sf-regular;
  letter-spacing: -0.15px;
  color: #000000;
  opacity: 0.5;
}

.move-to-input{
  @extend .reels-input;
  background-color: #F5F5F5;
  height: 23px;
  width: 105px;
  margin: 10px;
  border: 1px solid #E1E1E1;
  &:focus{
    background-color: white;
  }
}

  // Date Picker

.reels-date-picker-input{
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  height: 30px;
  width: 175px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
}

.reels-date-picker-text{
  @include sf-regular;
  font-size: 14px;
  letter-spacing: -0.15px;
  color: #212529;
}
.react-datepicker__day--selected{
  border-radius: 0.3rem;
  background-color: #81E727;
  color: #fff;
}

.react-datepicker__day--selected:hover{
  background-color: #81E727;
}

.react-datepicker__day--selected:focus{
  outline: none;
}
.react-datepicker__day--keyboard-selected{
  background-color: #81E727;
  color: white;
  font-weight: bold
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #81E727;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #81E727;
}

// PUBLISH
.publish-container{
  display: flex;
  align-items: center;
}

.publish-btn{
  @include reels-btn;
  display: flex;
  align-items: center;
  @include sf-medium;
  font-size: 14px;
  height: 31px;
  text-align: center;
}

.dialog-container{
  background-color: white;
  border-radius: 8.01966px;
  padding-left: 25px;
  padding-right: 25px;
  width: 484px;
}

.dialog-container-mob {
  @extend .dialog-container;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
}

.dialog-publish-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 44px;
  padding-bottom: 20px;
}

.dialog-publish-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 49px;
}

.reels-input-select-container{
  @include transition;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 30px;
  @include sf-regular;
  background: #FFFFFF;
  padding-left: 8px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid #E4EAF0;
  color: #212529;

  &:hover{
    cursor: pointer;
  }
}

.add-content-container{
  @include transition;
  @include noselect;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover{
    cursor: pointer;
    opacity: 0.7;
  }
}

.plus {
  position: absolute;
  left: 0%;
  right: 72.73%;
  top: 17.65%;
  bottom: 11.76%;

  background: #000000;
}

.dng-img{
  @include transition;
  width: 34px;
  height: 30px;
  border: 1px solid #E4EAF0;
  border-radius: 3px;
  object-fit: fill;
}

.dng-img:hover{
  cursor: pointer;
  opacity: 0.8;
}

.dng-img-mob{
  @extend .dng-img;
  width: 40px;
  height: 40px;
}

.wrapper-scroll-container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: overlay;
  margin-bottom: 100px;
}

@mixin reels-status{
  @include sf-semibold;
  font-size: 9px;
  line-height: 11px;
}

.reels-status-done{
  @include reels-status;
  color: #0EB565;
}

.reels-status-new{
  @include reels-status;
  color: #1399D3;
}

.reels-status-in-progress{
  @include reels-status;
  color: #D8B500;
}
.reels-status-select-hover:hover{
  cursor: pointer;
  opacity: 0.7;
}

@mixin reels-status-select{
  @include sf-semibold;
  @include transition;
  @extend .reels-status-select-hover;
  border: 0.7px solid;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 9px;
  line-height: 11px;
  padding: 6px;
}

.reels-status-select-done{
  @include reels-status-select;
  color: #0EB565;
  fill: #0EB565;
  border-color: #0EB565;
}

.reels-status-select-new{
  @include reels-status-select;
  color: #1399D3;
  fill: #1399D3;
  border-color: #1399D3;
}

.reels-status-select-in-progress{
  @include reels-status-select;
  color: #D8B500;
  fill: #D8B500;
  border-color: #D8B500;
}

.reels-status-select-admin{
  @include reels-status-select;
  color: #212525;
  fill: #212525;
  border-color: #212525;
  text-transform: uppercase;
}

.reels-close-navigation{
  @include transition;
}

.reels-close-navigation:hover{
  cursor: pointer;
  opacity: 0.8;
}

.details-container {
  @include reels-container;
  display: flex;
  flex-direction: column;
  width: 468px;
  padding-left: 24px;
  padding-right: 24px;
}

.details-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 27px;
}

.details-body{
  display: flex;
  padding-top: 18px;
  flex-direction: column;
}

.details-sub-body{
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  width: 100%;
}

.list-img{
  width: 54px;
  height: 40px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  margin-left: 8px;
  object-fit: cover;
}

.details-footer{
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
}

.details-footer-mob{
  @extend .details-footer;
  padding-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  margin-top: auto;
}

.dng-input-container{
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  width: 200px;
}

.dng-input-container-mob{
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  width: 100%;
}

.hover-icon {
  @include transition;
}

.hover-icon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.loading-timing-btn {
  @include transition;
  @include sf-medium;
  letter-spacing: -0.15px;
  font-size: 9px;
  line-height: 19px;
  background: #E4EAF0;
  border-radius: 3px;
  height: 22px;
  padding-left: 11px;
  padding-right: 11px;
  text-align: center;
  &:hover{
    cursor: pointer;
    opacity: 0.8;
  }
}

.published-value {
  @include sf-medium;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #7F8489;
}

.reels-input-add-file-btn {
  @include transition;
  width: 19px;
  height: 19px;
  background: url(../imgs/add-file-img.png) no-repeat;
  background-size: contain;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.reels-input-delete-file-btn {
  @include transition;
  width: 19px;
  height: 19px;
  background: url(../imgs/delete_tag-icon.svg) no-repeat;
  background-size: contain;
  z-index: 2;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.music-sign-img {
  @include transition;
  width: 24px;
  height: 24px;
  background: url(../imgs/music-sign.png) no-repeat;
  background-size: contain;
}

.reels-hide-arrow {
  @include transition;
  background: url(../imgs/hide-arrow.svg) no-repeat scroll 22px 22px;
  background-position: center;
  width: 22px;
  height: 22px;
  &:hover {
    cursor: pointer;
  }
  &-active {
    @extend .reels-hide-arrow;
    transform: rotateZ(90deg);
  }
}

.collection-simple-video{
  width: 160px;
  height: 103px;
  background-color: white;
  border-radius: 10px;
  object-fit: cover;
}

.collection-simple-list-item {
  display: flex;
  align-items: center;
  width: 160px;
  height: 103px;
  position: relative;
}

.collection-simple-name {
  @include sf-semibold;
  color: black;
  font-size: 14px;
  letter-spacing: -0.2px;
}

.collection-simple-list-item-active {
  @extend .collection-simple-list-item;
}
