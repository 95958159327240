.login-container{
  width: 26vw;
  margin: 27vh auto;
  box-shadow: 0 3px 9px 0 rgba(0,0,0,0.07);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-logo-div{
  padding-top: 47px;
  display: flex;
  align-items: center;
}

.login-logo{
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 3px solid #000000;
}

.login-logo-text{
  @include sf-semibold;
  letter-spacing: 0.01em;
  padding-left: 9px;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}

.login-input-div{
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.login-input{
  width: 16vw;
  height: 30px;
  @include sf-regular;
  font-size: 14px;
  line-height: 17px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #DBDBDB;
  margin-top: 40px;
}

.login-input:focus{
  outline: none;
  border-bottom: 1px solid #81E727;
}

.login-input::placeholder {
  color: #A7A8AA;
  font-size: 14px;
  line-height: 17px;
}

.login-btn {
  @include reels-btn();
  width: 16vw;
  height: 40px;
  margin-bottom: 45px;
  margin-top: 35px;
  text-transform: uppercase;
}

.login-btn:disabled{
  color: rgb(0, 0, 0);
  background-color: #569b1d;
}

.err-msg{
  font-size: 13px;
  padding-top: 50px;
  color: #f75130;
  width: 70%;
  @include sf-regular;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .login-container{
    width: 50vw;
    margin: 35vh auto;

  }
  .login-input{
    width: 36vw;
  }

  .login-btn{
    width: 36vw;
  }
  .login-logo-div{
    padding-top: 40px;
  }
}

@media screen and (max-width: 800px) {
  .login-container{
    width: 50vw;
    margin: 35vh auto;

  }
  .login-input{
    width: 36vw;
  }

  .login-btn{
    width: 36vw;
  }
  .login-logo-div{
    padding-top: 40px;
  }
}

@media screen and (max-width: 450px) {
  .login-container{
    width: 80vw;
    margin: 12vh auto;

  }
  .login-input{
    width: 60vw;
  }

  .login-btn{
    width: 60vw;
  }
  .login-logo-div{
    padding-top: 40px;
  }
}

@media screen and (max-height: 450px) {
  .login-div{
    margin: 4vh auto;
  }
}

@media screen and (max-height: 400px) {
  .login-container{
    width: 80vw;
    margin: 2vh auto;

  }
  .login-input{
    width: 60vw;
  }

  .login-input-div{
    padding-bottom: 8px;
  }

  .login-btn{
    width: 60vw;
  }
  .login-logo-div{
    padding-top: 20px;
  }
}
