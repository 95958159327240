.music-container{
  display: flex;
  height: 100%;
  max-width: 1000px;
  gap: 21px;
  overflow: visible;
  margin-left: auto;
  margin-right: auto;

}

.track-categories-container {
  display: flex;
  flex-direction: column;
  width: 220px;
  background-color: #ffffff;
}

.reels-add-header-add-tack-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  background-color: #f7f7f7;
  @include sf-semibold;
  font-size: 16px;
  line-height: 22px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.track-category-item {
  @include sf-medium;
  @include noselect;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  margin-right: 10px;
  background-color: #FFFFFF;
  border-top: 1px solid var(--main-green, #ffffff);
  border-bottom: 1px solid var(--main-green, #ffffff);
  &:hover {
    border-top: 1px solid var(--main-green, #81E727);
    border-bottom: 1px solid var(--main-green, #81E727);
  }
}

.tracks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reels-header-tracks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  width: 100%;
}

.all-tracks-text {
  @include sf-semibold;
  @include noselect;
  color: #7F8489;
  font-size: 16px;
  line-height: 22px;
}

.reels-header-track-add {
  @include sf-semibold;
  @include noselect;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  gap: 11px;
  align-items: center;
  padding-right: 16px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.reels-track-tabel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  .header-tabel {
    @include sf-semibold;
    height: 24px;
    font-size: 10px;
    line-height: 18px;
    display: flex;
    background-color: #f7f7f7;
    border-bottom: #e4eaf0 1px solid;
  }

  .column {
    display: flex;
    align-items: center;
  }
  .header-right-border {
    border-right: #E4EAF0 1px solid;
  }
  .item-right-border {
    border-right: #ffffff 1px solid;
  }

  .id-column {
    @extend .column;
    margin-left: 10px;
    flex: 1 50px;
  }
  .cover-column {
    @extend .column;
    justify-content: center;
    flex: 1 50px;
  }
  .artist-column {
    @extend .column;
    flex: 3 210px;
    margin-left: 10px;
  }
  .song-column {
    @extend .column;
    flex: 3 200px;
    margin-left: 10px;
  }
  .duration-column {
    @extend .column;
    justify-content: center;
    flex: 1 50px;
  }
  .source-column {
    @extend .column;
    justify-content: center;
    flex: 2 63px;
  }
  .play-count-column {
    @extend .column;
    justify-content: center;
    flex: 1 45px;
  }
  .download-count-column {
    @extend .column;
    justify-content: center;
    flex: 1 45px;
  }

  .track-item {
    @include sf-medium;
    display: flex;
    background-color: white;
    border: white 1px solid;
    border-bottom: #e4eaf0 1px solid;
    height: 36px;
    font-size: 13px;
    line-height: 18px;
    &-selected {
      @extend .track-item;
      background-color: #D3FFB9;
    }
    &:hover {
      @extend .track-item;
      border: #81e628 1px solid;
    }
  }
}

.track-cover-container {
  position: relative;
  width: 28px;
  height: 28px;

  .track-cover {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    border: 0.7px solid #E4EAF0;
  }

  .premium-icon {
    position: absolute;
    z-index: 2;
    top: -6px;
    right: -12px;
  }
}

.source-badge {
  display: inline-flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  border-radius: 4px;
  &-onelight {
    @extend .source-badge;
    background: #D3FFB9;
  }
  &-soul {
    @extend .source-badge;
    background: #F0D5FF;

  }
}
