.settings-container{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;
}

.remote-config-container {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 433px;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.remote-config-container-mob {
  width: 90%;
}

.remote-config-header {
  display: flex;
  justify-content: space-between;
}

.remote-config-body {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.remote-config-header-text {
  @include sf-regular;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #212525;
}

.remote-config-switcher-text {
  @include sf-bold;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #7F8489;
}

.remote-config-prop-container {
  display: flex;
  flex-direction: column;
}

.remote-config-prop-title {
  @include sf-regular;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.15px;
  padding-bottom: 5px;
  color: #7F8489;
}

.remote-config-prop-text {
  @include sf-regular;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.15px;
  color: #212525;
}

.remote-config-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
}
